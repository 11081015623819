<script setup>

import collect from "collect.js";

const storeConfig = useStoreConfig()
const { wholesaleConfig, quickViewIsActive } = storeToRefs(storeConfig)
const {isB2BAccount, isVatExcludeForB2B} = useHelper()
const { addToBasket, addCartLoading, isCartAdded } = await useBasket();
const { isWholesaleUser } = await useCustomer();

const searchInput = useState("searchInput")
const search = useState("searchProduct")

const {items} = usePagination()
const {openQuickModal} = useQuickProductView()

const {
  selectedWarehouse,
  isDouble,
  maxQtyForProduct
} = useListing()

const filteredCustomerPrice = useState('filteredCustomerPrice', () => [])
const availableSearchFields = useState('wholesale::availableSearchFields', () => wholesaleConfig.value?.availableSearchFields)

const getFeatureLabel = (features, item) => {
  const collection = collect(features).where('attributeId', '=', parseInt(item.value)).first()
  return typeof collection === 'object' && collection?.attributeItemName ? collection?.attributeItemName : null;
}

const getFeatureImage = (features, item) => {
  const collection = collect(features).where('attributeId', '=', parseInt(item.value)).first()
  return typeof collection === 'object' && collection?.attributeItemImage ? collection?.attributeItemImage : null;
}

const mapProductAttributes = (productAttributes) => {

  return collect(productAttributes).map((value, key) => {
    return {
      attribute_type : key,
      attribute_value : value
    }
  }).toArray()
}

</script>

<template>
  <table class="list-table">
    <thead class="">
    <tr>
      <!-- Dinamik Alan Başlangıcı -->
      <th scope="col" class="list-table--th" v-for="item in availableSearchFields" :key="item" :class="[{'max-w-[225px]' : item.value === 'base::productTitle', 'pl-4 pr-3 sm:pl-6 w-[85px]' : item.value === 'base::productImage'}]">
        <SectionPopupFilter :payload="item">
          {{ !inArray(item.value, ['base::variableCoefficientUnitCalculation', 'base::variableCoefficientUnitSum', 'base::productImage']) && getLocaleString(item.label) ? getLocaleString(item.label) : '' }}
        </SectionPopupFilter>
      </th>
      <th scope="col" class="list-table--th-action"></th>
    </tr>
    </thead>
    <tbody class="list-table--body">
    <tr
        v-for="(product, pageIdx) in items"
        :key="product.productId"
        class="list-table--tr"
    >
      <!-- Dinamik Alanlar -->
      <td class="list-table--td" v-for="item in availableSearchFields" :key="item.value" :class="[{
                    '!whitespace-normal max-w-[225px]' : item.value === 'base::productTitle',
                    'w-[85px]' : item.value === 'base::productImage',
                    'max-w-[150px]' : item.value === 'base::variableCoefficientUnitCalculation'
                  }]">

        <!-- Ürün Görseli -->
        <template v-if="item.value === 'base::productImage'">
          <div class="relative group">
            <NuxtImg
                :src="getImage(product.selectedVariant?.thumbnail)"
                width="120"
                height="120"
                :alt="product?.selectedVariant?.title"
                class="list-table--td-img"
                :class="{'grayscale' : parseInt(product?.selectedVariant?.primaryStockData?.quantity) === 0}"
            />
            <div class="list-table--td-img-zoom-wrapper">
              <NuxtImg
                  :src="getImage(product.selectedVariant?.thumbnail)"
                  width="512"
                  height="512"
                  :alt="product?.selectedVariant?.title"
                  class="list-table--td-img-zoom-img"
                  :class="{'grayscale' : parseInt(product?.selectedVariant?.primaryStockData?.quantity) === 0}"
              />
            </div>
          </div>
        </template>

        <!-- Ürün Başlığı -->
        <template v-else-if="item.value === 'base::productTitle'">
          <div>{{ product?.selectedVariant?.title }}</div>
        </template>

        <!-- Marka Adı -->
        <template v-else-if="item.value === 'base::brand'">
          <NuxtImg class="w-full object-contain h-4 max-w-[100px]" width="128" :src="product.brandImage" v-if="product?.brandImage" />
          <span class="searchList_brand" v-else>{{product.brandName}}</span>
        </template>

        <!-- Adet / Depo Bilgisi -->
        <template v-else-if="item.value === 'base::warehouseSection'">
          <p v-if="parseInt(product?.selectedVariant?.qtySumForAllWarehouse) === 0" class="text-red text-sm">
            <StockOutText />
          </p>

          <template v-else>
            <!-- B2B açık olan depo adetlerini birleşik göster (toplam adet göster) -->
            <div class="cursor-default" v-if="wholesaleConfig?.only_show_default_warehouse_qty === 1">
              {{product?.selectedVariant?.qtySumForAllWarehouse}}
            </div>

            <!-- Ön tanımlı depoya ait adetleri göster" işaretlendiği zaman, default depoda adet yoksa sonrakini gösteriyor -->
            <div class="cursor-default" v-else-if="wholesaleConfig?.only_show_default_warehouse_qty === 2">
              {{ product?.selectedVariant?.primaryStockData?.warehouseName }} : {{parseInt(product?.selectedVariant?.primaryStockData?.quantity)}}
            </div>

            <!-- B2B açık olan depo adetlerini ayrı ayrı göster -->
            <WarehousePopover
                :only-show-qty="wholesaleConfig?.only_show_default_warehouse_qty === 3"
                :product="product"
                :variantId="product.selectedVariant?.variantId"
                v-else
            />

            <!-- Toplam adet gösterimi aktif ise tüm depolardaki toplam adedi gösteriyoruz -->
            <div class="mt-1 text-2xs text-gray-600" v-if="wholesaleConfig?.only_show_default_warehouse_qty === 0 && wholesaleConfig?.show_total_qty">Toplam {{product.selectedVariant?.unitName ? product.selectedVariant?.unitName : 'Adet'}} : {{product?.selectedVariant?.qtySumForAllWarehouse}}</div>
          </template>
        </template>

        <!-- Model Kodu -->
        <template v-else-if="item.value === 'base::mainSku'">
          <span class="searchList_modelSku">{{ product.modelSku }}</span>
        </template>

        <!-- Kategori Adı -->
        <template v-else-if="item.value === 'base::categoryName'">
          <span class="searchList_categoryName" v-if="Array.isArray(product.categories) && product.categories.length > 0">{{ product.categories[product.categories.length - 1].categoryName }}</span>
        </template>

        <!-- Barkod -->
        <template v-else-if="item.value === 'base::barcode'">
          <span class="searchList_barcode">{{ product.selectedVariant.barcodeId }}</span>
        </template>

        <!-- Renk -->
        <template v-else-if="item.value === 'base::color'">
          <span class="searchList_barcode">{{ product.selectedVariant.colorName }}</span>
        </template>

        <!-- Beden -->
        <template v-else-if="item.value === 'base::size'">
          <span class="searchList_barcode">{{ product.selectedVariant.size?.label }}</span>
        </template>

        <!-- Adet Input -->
        <template v-else-if="item.value === 'base::qtyInput'">
          <div class="flex items-center justify-center">
            <FormInputCounter
                class="max-w-[150px]"
                v-model="product.cartQty"
                :min="1"
                :max="maxQtyForProduct(product)"
                input-size="sm"
            />
          </div>
        </template>

        <template v-else-if="isWholesaleUser && item.value === 'base::listPrice'">
          <PriceUpdateModeDescriptionForB2B>
            <div>{{getCurrencySymbol(product?.selectedVariant?.primaryStockData?.currency)}} {{ product?.selectedVariant?.primaryStockData?.wholesaleListPrice }}</div>
            <!-- KDV Bilgilendirmesi ve Dahil / Hariç Durumu -->
            <SectionProductVatRateInformation :selected-variant="product?.selectedVariant" />
          </PriceUpdateModeDescriptionForB2B>
        </template>

        <template v-else-if="isWholesaleUser && item.value === 'base::cashPrice'">
          <PriceUpdateModeDescriptionForB2B>
            <div>{{getCurrencySymbol(product?.selectedVariant?.primaryStockData?.currency)}} {{ product?.selectedVariant?.primaryStockData?.wholesaleCashPrice }}</div>
            <!-- KDV Bilgilendirmesi ve Dahil / Hariç Durumu -->
            <SectionProductVatRateInformation :selected-variant="product?.selectedVariant" />
          </PriceUpdateModeDescriptionForB2B>
        </template>

        <template v-else-if="isWholesaleUser && item.value === 'base::creditCardPrice'">
          <PriceUpdateModeDescriptionForB2B>
            <div>{{getCurrencySymbol(product?.selectedVariant?.primaryStockData?.currency)}} {{ product?.selectedVariant?.primaryStockData?.wholesaleCardPrice }}</div>
            <!-- KDV Bilgilendirmesi ve Dahil / Hariç Durumu -->
            <SectionProductVatRateInformation :selected-variant="product?.selectedVariant" />
          </PriceUpdateModeDescriptionForB2B>
        </template>

        <template v-else-if="isWholesaleUser && item.value === 'base::wholesalePrice'">
          <PriceUpdateModeDescriptionForB2B>
            <div>{{getCurrencySymbol(product?.selectedVariant?.primaryStockData?.currency)}} {{ product?.selectedVariant?.primaryStockData?.wholesalePrice }}</div>
            <!-- KDV Bilgilendirmesi ve Dahil / Hariç Durumu -->
            <SectionProductVatRateInformation :selected-variant="product?.selectedVariant" />
          </PriceUpdateModeDescriptionForB2B>
        </template>

        <template v-else-if="!isWholesaleUser && item.value === 'base::cashPrice'">
          <PriceUpdateModeDescriptionForB2B>
            <div>{{getCurrencySymbol(product?.selectedVariant?.primaryStockData?.currency)}} {{ product?.selectedVariant?.primaryStockData?.salePrice }}</div>
            <!-- KDV Bilgilendirmesi ve Dahil / Hariç Durumu -->
            <SectionProductVatRateInformation :selected-variant="product?.selectedVariant" />
          </PriceUpdateModeDescriptionForB2B>
        </template>

        <!-- Değişken Katsayılı Birim Hesaplaması -->
        <template v-else-if="item.value === 'base::variableCoefficientUnitCalculation'">
          <SectionProductVariableCoefficientUnitCalculation
              :price="product?.selectedVariant?.primaryStockData?.wholesalePrice"
              :currency="product.selectedVariant?.primaryStockData.currency"
              :maxQtyForProduct="maxQtyForProduct(product)"
              :unitName="product?.selectedVariant?.unitName"
              v-model:qty="product.cartAttributes.qty"
              v-model:width="product.cartAttributes.width"
              v-model:totalQty="product.cartQty"
              v-if="parseInt(product?.selectedVariant?.qtySumForAllWarehouse) !== 0"
          />
        </template>

        <!-- Değişken Katsayılı Birim Hesap Toplamı -->
        <template v-else-if="item.value === 'base::variableCoefficientUnitSum'">
          <SectionProductVariableCoefficientUnitSum
              :price="product?.selectedVariant?.primaryStockData?.wholesalePrice"
              :currency="product.selectedVariant?.primaryStockData.currency"
              :totalQty="product.cartQty"
              :unitName="product?.selectedVariant?.unitName"
              v-if="parseInt(product?.selectedVariant?.qtySumForAllWarehouse) !== 0"
          />
        </template>

        <!-- Ürün Özellikleri Alanı -->
        <template v-else>
          <div
              class="flex space-x-1 items-center justify-center gap-2.5"
              v-tooltip="{ content : item?.show_tooltip ? getFeatureLabel(product.features, item) : null}"
          >
            <NuxtImg
                class="list-table--attribute-img"
                width="256"
                height="256"
                :src="getFeatureImage(product.features, item)"
                v-if="getFeatureImage(product.features, item) && inArray(item?.show_icon_mode, [1,2])"
            />
            <span v-if="item?.show_icon_mode !== 1">{{getFeatureLabel(product.features, item)}}</span>
          </div>
        </template>
      </td>

      <!-- Müşteri Fiyatı -->
      <td v-if="isB2BAccount && filteredCustomerPrice.length>0  && product?.selectedVariant?.primaryStockData?.customerPrice" class="list-table--td">
        <PriceUpdateModeDescriptionForB2B>
          <div>{{getCurrencySymbol(product?.selectedVariant?.primaryStockData?.currency)}} {{ product?.selectedVariant?.primaryStockData?.customerPrice }}</div>
          <div class="product-vat-info" v-if="isVatExcludeForB2B || (!isVatExcludeForB2B && !product?.selectedVariant?.primaryStockData?.vatInclude)">
            {{$t('widgets.vat-info.excluded', {vatRate : product?.selectedVariant?.primaryStockData?.vatRate })}}
          </div>
        </PriceUpdateModeDescriptionForB2B>
      </td>

      <td class="list-table--td-action">
        <div class="inline-flex flex-col gap-1.5 w-full max-w-[160px]">
          <FormButton size="sm" color="btn-view-product" @click.prevent="quickViewIsActive ? openQuickModal(product.selectedVariant?.url) : navigateTo(product.selectedVariant?.url)">
            {{ $t('listing.view-product') }}
          </FormButton>
          <FormButton
              size="sm"
              color="primary"
              :loading="addCartLoading === product.selectedVariant.variantId"
              @click="addToBasket(product.selectedVariant.variantId, product.cartQty, product.selectedVariant?.primaryStockData.rawCurrency, product.selectedVariant?.primaryStockData?.warehouseId, null, false, true, null, null, mapProductAttributes(product.cartAttributes))"
          >
            <template #icon>
              <LucideCheck class="size-3.5 mr-2" v-if="isCartAdded(product.selectedVariant.variantId)"/>
              <LucideShoppingCart class="size-3.5 mr-2" v-else/>
            </template>
            {{ $t('app.add-to-cart') }}
          </FormButton>
          <ProductCriticalQuantity :quantity="parseInt(product.selectedVariant?.primaryStockData?.quantity)" />
          <div class="text-xs text-green-700 font-medium text-center" v-if="isCartAdded(product.selectedVariant.variantId)">
            {{$t('listing.exists-in-basket', { count : isCartAdded(product.selectedVariant.variantId), unit : product.selectedVariant.unitName })}}
          </div>
        </div>
      </td>
    </tr>
    <!-- </div> -->
    </tbody>
  </table>
</template>

<style scoped>

</style>