<script setup>
import {Popover, PopoverButton, PopoverPanel} from "@headlessui/vue";
import {FunnelIcon, ChevronDownIcon, ChevronUpIcon, BarsArrowDownIcon} from "@heroicons/vue/24/solid";
import collect from "collect.js";
import {inArray} from "~/composables/useHelper";

const props = defineProps(['payload'])

const {activeFilters, customSortOptions, checkVisible} = useFilter()
const {orderBy} = usePagination()

const search = ref("")

const attributeList = ref([])
const facets = useState('facets')
const filters = useState('filters')

const customQueryName = ref()
const filterQueryType = computed(() => {
  if (props.payload.value === 'base::brand') {
     return 'brand'
  } else if (props.payload.value === 'base::mainSku') {
    return 'mainSku'
  } else if (props.payload.value === 'base::barcode') {
    return 'barcode'
  } else if (props.payload.value === 'base::qty') {
    return 'qty'
  } else if (props.payload.value === 'base::listPrice') {
    return 'listPrice'
  } else if (props.payload.value === 'base::creditCardPrice') {
    return 'creditCardPrice'
  } else if (props.payload.value === 'base::wholesalePrice') {
    return 'wholesalePrice'
  } else if (props.payload.value === 'base::cashPrice') {
    return 'cashPrice'
  } else if (props.payload.value === 'base::color') {
    return 'color'
  } else if (props.payload.value === 'base::size') {
    return 'size'
  } else {
    return customQueryName.value
  }
})
const isSortable = computed(() => inArray(props.payload.value, ['base::qty', 'base::listPrice', 'base::creditCardPrice', 'base::wholesalePrice', 'base::cashPrice']))
const isFilterable = computed(() => !isSortable.value && !inArray(props.payload.value, ['base::mainSku', 'base::productImage', 'base::productTitle', 'base::warehouseSection', 'base::qtyInput', 'base::categoryName', 'base::variableCoefficientUnitCalculation', 'base::variableCoefficientUnitSum']))

const isAttributeFilter = computed(() => !inArray(props.payload.value, ['base::qty', 'base::brand', 'base::color', 'base::size', 'base::barcode', 'base::mainSku', 'base::listPrice', 'base::creditCardPrice', 'base::wholesalePrice', 'base::cashPrice', 'base::productImage', 'base::productTitle', 'base::warehouseSection', 'base::qtyInput', 'base::categoryName', 'base::variableCoefficientUnitCalculation', 'base::variableCoefficientUnitSum']))

const currentFilter = computed(() => {
  if (props.payload.value === 'base::brand') {
    return collect(filters.value).where('queryName', '=', 'brand').pluck('options').first()
  } else if (props.payload.value === 'base::color') {
    return collect(filters.value).where('queryName', '=', 'color').pluck('options').first()
  } else if (props.payload.value === 'base::size') {
    return collect(filters.value).where('queryName', '=', 'size').pluck('options').first()
  } else if (props.payload.value === 'base::mainSku') {
    return Object.keys(facets.value?.modelSku).map((key) => {
      return {
        label : key,
        value : key
      }
    })
  } else {
    return attributeList.value
  }

})

const filteredList = computed(() => {
  if (!currentFilter.value) {
    return []
  }

  return currentFilter.value.filter(filter => {
    return filter.label.toLowerCase().includes(search.value.toLowerCase())
  })
})

const setFilter = (item) => {
  if (inArray(props.payload.value, ['base::brand', 'base::mainSku', 'base::color', 'base::size']) || isAttributeFilter.value) {
    if (!activeFilters.value[filterQueryType.value]) {
      activeFilters.value[filterQueryType.value] = []
    }

    if (inArray(item.value, activeFilters.value[filterQueryType.value])) {
      // delete
      const index = activeFilters.value[filterQueryType.value].indexOf(item.value);
      if (index > -1) activeFilters.value[filterQueryType.value].splice(index, 1); // 2nd parameter means remove one item only
    } else {
      activeFilters.value[filterQueryType.value].push(item.value)
    }

  }
}

/**
 * Özel alan değerlerini API'den getirir
 * @returns {Promise<void>}
 */
const getAttributeValues = async () => {
  const data = await $fetch('/api/attributes/values', {
    server : false,
    query : {
      attributeId: props.payload.value
    }
  })

  if (data?.values.length > 0) {
    attributeList.value = data?.values
    customQueryName.value = data?.attributeQuery
  }
}

const isActive = computed(() => {
  // Filtrelenebilir içerik için isActive kontrolü
  if (isFilterable.value) {
    return typeof activeFilters.value[filterQueryType.value] !== 'undefined' && activeFilters.value[filterQueryType.value]?.length > 0
  } else { // sıralanabilir içerikler için isActive kontrolü
    return orderBy.value.code === props.payload.value// collect(customSortOptions.value).where('code', '=', props.payload.value).where('value', '=', orderBy.value).count() > 0
  }
})

const checkStrictActiveState = (direction) => {
  return orderBy.value?.code === props.payload.value && orderBy.value?.direction === direction
}

const setOrderBy = (direction) => {
  orderBy.value = collect(customSortOptions.value)
      .where('code', '=', props.payload.value)
      .where('direction', '=', direction)
      .first()
}

onMounted(() => {
  if (isAttributeFilter.value && isFilterable.value) {
    getAttributeValues()
  }
})

</script>

<template>
  <Popover class="relative flex space-x-1 justify-center items-center w-full">
    <span>
      <slot />
    </span>

    <PopoverButton class="flex h-4 w-4 items-center justify-center hover:bg-gray-200 rounded-full relative outline-0 focus:outline-0" v-if="isSortable || isFilterable">
        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-secondary/30" v-if="isActive"></span>
        <BarsArrowDownIcon class="w-3 h-3" :class="isActive ? 'text-secondary' : 'text-gray-400'" v-if="isSortable" />
        <FunnelIcon class="w-2.5 h-2.5" :class="isActive ? 'text-secondary' : 'text-gray-400'" v-else />
    </PopoverButton>

    <PopoverPanel class="absolute shadow left-1/2 -translate-x-1/2 top-full mt-3.5 z-10 bg-white border p-2 rounded w-[200px]">
        <div class="absolute bottom-full w-6 left-1/2 -translate-x-1/2 overflow-hidden inline-block">
          <div class=" h-4 w-4 bg-white border rotate-45 transform origin-bottom-left"></div>
        </div>

        <div class="flex space-x-1.5" v-if="isSortable">
          <FormButton class="w-full" :color="checkStrictActiveState('asc') ? 'secondary' : 'gray'" size="xs" @click="setOrderBy('asc')"><ChevronUpIcon class="h-3 w-3 mr-2" /><span class="text-xs">{{ $t('app.ascending') }}</span></FormButton>
          <FormButton class="w-full" :color="checkStrictActiveState('desc') ? 'secondary' : 'gray'" size="xs" @click="setOrderBy('desc')"><ChevronDownIcon class="h-3 w-3 mr-2" /><span class="text-xs">{{ $t('app.descending') }}</span></FormButton>
        </div>
        <template v-if="isFilterable">
          <!-- Ara -->
          <FormInput
              size="sm"
              class="mb-2"
              :placeholder="$t('app.search')"
              v-model="search"
              v-if="currentFilter?.length"
          />

          <!-- Listele -->
          <div class="max-h-52 overflow-y-auto space-y-1 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-transparent">
            <template v-if="inArray(payload.value, ['base::brand', 'base::mainSku', 'base::color', 'base::size']) || isAttributeFilter">
              <template v-for="item in filteredList.filter((i) => checkVisible(filterQueryType, i))">
                <div class="flex items-center space-x-1.5">
                  <input
                      @change="setFilter(item)"
                      :value="item.value"
                      :name="payload.value"
                      :id="`${filterQueryType}::${item.value}`"
                      :checked="inArray(item.value, activeFilters[filterQueryType])"
                      type="checkbox"
                      class="h-3 w-3 border-gray-300 rounded dark:bg-darkgray-400 dark:border-darkgray-400 focus:outline-none ring-0"
                  />

                  <label :for="`${filterQueryType}::${item.value}`" class="font-medium">{{item.label}}</label>
                </div>
              </template>
            </template>
          </div>
        </template>
    </PopoverPanel>
  </Popover>
</template>

<style scoped>

</style>